import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "Exception Logs" }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        loading: _ctx.loading == 'loading',
        data: _ctx.items.data,
        columns: _ctx.fields,
        pagination: _ctx.items,
        onEdit: _ctx.editRecord,
        onDestroy: _ctx.deleteRecord,
        onCreate: _ctx.createRecord,
        onFilter: _ctx.filterRecord,
        showCreateButton: false
      }, null, 8, ["loading", "data", "columns", "pagination", "onEdit", "onDestroy", "onCreate", "onFilter"])
    ]),
    _: 1
  }))
}

import {
  defineComponent,
  ref,
  computed,
  onMounted,
  reactive,
  toRefs,
} from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const form = reactive({
      id: "",
      sold_to: "",
      retailer_name: "",
    });
    const form_action = ref('save')
    const visible = ref(false);
    const store = useStore();
    const fields = ref([
      {
        title: "Url",
        dataIndex: "url",
      },
      {
        title: "Http Method",
        dataIndex: "http_method",
      },
      {
        title: "Error Message",
        dataIndex: "message",
      },
    ]);

    const index = (payload={page: 1}) => {
      store.dispatch("ExceptionLog/index", payload);
    };

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const { id, sold_to, retailer_name } = toRefs(form);
      id.value = record.id;
      sold_to.value = record.sold_to;
      retailer_name.value = record.retailer_name;
      visible.value = true;
    };

    const createRecord = () => {
      form_action.value = 'save'
      const { sold_to, retailer_name } = toRefs(form);
      sold_to.value = '';
      retailer_name.value = '';
      visible.value = true;
    };

    const deleteRecord = (record: any) => {
      store.dispatch("ExceptionLog/destroy", record.id);
    };

    const handleSaving = (record: any) => {
      form_action.value == 'save' ? storeRecord(record) : updateRecord(record);
    }

    const storeRecord = (payload = {}) => {
      store
        .dispatch("ExceptionLog/store", payload)
        .then(() => (visible.value = false));
    };

    const updateRecord = (payload = {}) => {
      store
        .dispatch("ExceptionLog/update", payload)
        .then(() => (visible.value = false));
    };

    const filterRecord = (payload: any) => {
      index(payload);
    }

    const items = computed(() => store.getters["ExceptionLog/items"]);
    const loading = computed(() => store.getters["ExceptionLog/status"]);

    onMounted(index);

    return {
      loading,
      items,
      fields,
      visible,
      form,
      editRecord,
      deleteRecord,
      storeRecord,
      createRecord,
      updateRecord,
      handleSaving,
      filterRecord
    };
  },
});
